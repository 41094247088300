import React from "react"
import styled from "styled-components"
import { PageTitle } from "../../components/PageTitle"
import { Layout } from "../../layout/Layout"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  > ol {
    padding: 20px;
  }
`

export const Polityka = () => {
  return (
    <Layout>
      <PageTitle title="POLITYKA PRYWATNOŚCI" />
      <Content>
        <ol>
          <li>
            Serwis nie zbiera w sposób automatyczny żadnych informacji, z
            wyjątkiem informacji zawartych w plikach cookies.
          </li>
          <li>
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
            stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
            strony internetowej, z której pochodzą, czas przechowywania ich na
            urządzeniu końcowym oraz unikalny numer.
          </li>
          <li>
            Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu
            pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
          </li>
          <li>Pliki cookies wykorzystywane są w celu:</li>
          <li>
            dostosowania zawartości stron internetowych Serwisu do preferencji
            Użytkownika oraz optymalizacji korzystania ze stron internetowych; w
            szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika
            Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do
            jego indywidualnych potrzeb;
          </li>
          <li>
            tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
            Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia
            ulepszanie ich struktury i zawartości;
          </li>
          <li>
            utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której
            Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
            loginu i hasła;
          </li>
          <li>
            W ramach Serwisu stosowane są następujące rodzaje plików cookies:
          </li>
          <li>
            „niezbędne” pliki cookies, umożliwiające korzystanie z usług
            dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies
            wykorzystywane do usług wymagających uwierzytelniania w ramach
            Serwisu;
          </li>
          <li>
            pliki cookies służące do zapewnienia bezpieczeństwa, np.
            wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
            ramach Serwisu;
          </li>
          <li>
            „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o
            sposobie korzystania ze stron internetowych Serwisu;
          </li>
          <li>
            „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych
            przez Użytkownika ustawień i personalizację interfejsu Użytkownika,
            np. w zakresie wybranego języka lub regionu, z którego pochodzi
            Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;
          </li>
          <li>
            „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom
            treści reklamowych bardziej dostosowanych do ich zainteresowań.
          </li>
          <li>
            W wielu przypadkach oprogramowanie służące do przeglądania stron
            internetowych (przeglądarka internetowa) domyślnie dopuszcza
            przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
            Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
            dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
            szczególności w taki sposób, aby blokować automatyczną obsługę
            plików cookies w ustawieniach przeglądarki internetowej bądź
            informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika
            Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi
            plików cookies dostępne są w ustawieniach oprogramowania
            (przeglądarki internetowej).
          </li>
          <li>
            Operator Serwisu informuje, że ograniczenia stosowania plików
            cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
            stronach internetowych Serwisu.
          </li>
          <li>
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
            i wykorzystywane mogą być również przez współpracujących z
            operatorem Serwisu reklamodawców oraz partnerów.
          </li>
          <li>
            Więcej informacji na temat plików cookies znajdziesz pod adresem
            http://wszystkoociasteczkach.pl/ lub w sekcji „Pomoc” w menu
            przeglądarki internetowej.
          </li>
        </ol>
      </Content>
    </Layout>
  )
}

export default Polityka
